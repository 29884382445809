import { Injectable } from "@angular/core";
import {Subject} from "rxjs";
import {JsonApiService } from "@app/core/services/json-api.service";
import {Globals} from "@app/modulo-global/globals";
import {UsuarioService} from "@app/modulo-usuario/usuario/usuario.service";
import {NotificationService} from "@app/core/services/notification.service";
import {Router} from "@angular/router";
import {Usuario} from "../../../models/usuario";

@Injectable()
export class UserService {

  public user: Subject<any>;

  public userInfo = {
    username: 'Guest',
    rol: 'NONE',
  };

  constructor(
      private jsonApiService: JsonApiService,
      private global: Globals,
      private usuarioService: UsuarioService,
      public notificationService: NotificationService,
      private router: Router,
  ) {
    this.user = new Subject();
  }

  getLoginInfo(): Usuario {
    const userId = Number(localStorage.getItem('userId'));
    if (userId > 0) {
      if (!this.global.usuario) {
        this.usuarioService
            .getUserDataFromServer(userId)
            .then((res) => {
              this.global.usuario = res;
              this.userInfo.username = res.nombre;
              this.userInfo.rol = res.TipoUsuario.rol;
              localStorage.setItem('role', res.TipoUsuario.rol);
            }).catch((err) => {
          this.lockedAccount(err);
        });
      } else {
        this.usuarioService
            .getUserSessionFromServer()
            .then((res) => {
            }).catch((err) => {
          this.lockedAccount(err);
        });
      }
    }
    return this.global.usuario;
  }

  lockedAccount(err) {
    this.notificationService.smallBox({
      title: 'ERROR DE SESIÓN',
      content: err.message,
      color: '#ffae43',
      timeout: 5000,
      icon: 'fa fa-exclamation-triangle'
    });
    this.router.navigate(['/auth/locked']);
  }

  public getRole(): Promise<any> {
    return Promise.all([
      this.usuarioService
          .getUserDataFromServer(Number(localStorage.getItem('userId')))
          .then((res) => {
            this.global.usuario = res;
            this.userInfo.username = res.nombre;
            this.userInfo.rol = res.TipoUsuario.rol;
          }).catch((err) => {
        this.lockedAccount(err);
      })
    ]).then(() => {return this.userInfo.rol})
  }

  public getUserInfo(): Promise<Usuario> {
    return Promise.all([
      this.usuarioService
          .getUserDataFromServer(Number(localStorage.getItem('userId')))
          .then((res) => {
            this.global.usuario = res;
            this.userInfo.username = res.nombre;
            this.userInfo.rol = res.TipoUsuario.rol;
          }).catch((err) => {
        this.lockedAccount(err);
      })
    ]).then(() => {return this.global.usuario})
  }


}
