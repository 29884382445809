import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginInfoComponent} from "./login-info/login-info.component";
import {LogoutComponent} from "./logout/logout.component";
import {UsuarioService} from "@app/modulo-usuario/usuario/usuario.service";
import {LoginService} from "@app/auth/login/login.service";
import {DataProviderService} from "@app/data-provider.service";
import {Globals} from "@app/modulo-global/globals";
import {Router} from "@angular/router";

@NgModule({
    imports: [CommonModule],
    declarations: [LoginInfoComponent, LogoutComponent],
    exports: [LoginInfoComponent, LogoutComponent],
    providers: [UsuarioService, DataProviderService, LoginService, Globals]
})

export class UserModule {

    constructor(
        private usuarioService: UsuarioService,
        private router: Router,
    ) {}

    public checkUserPrivileges(rolExigido) : boolean {
        let rol = localStorage.getItem('role');
        if (!rol) {
            this.usuarioService
                .getUserDataFromServer()
                .then((res) => {
                    localStorage.setItem('role', res.TipoUsuario.rol);
                    return rolExigido == res.TipoUsuario.rol;
                })
        } else {
            return rol == rolExigido;
        }
    }

    public chechUserPrivilegesOrRedirect() {
        if (localStorage.getItem('role')) {
            return this.checkUserPrivileges('ROLE_ADMIN') || this.checkUserPrivileges('ROLE_RECEPCIONIST');
        } else {
            this.usuarioService
                .getUserDataFromServer()
                .then((res) => {
                    localStorage.setItem('role', res.TipoUsuario.rol);
                    return 'ROLE_ADMIN' == res.TipoUsuario.rol || 'ROLE_RECEPCIONIST' == res.TipoUsuario.rol;
                })
        }
    }

}
