import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { NgSelectModule } from '@ng-select/ng-select';
import {FormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEsAr, 'es-Ar');
import {VersionCheckService} from "@app/shared/utils/version-checker.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      SharedModule,
      CoreModule,
      NgSelectModule,
      FormsModule,
      MatButtonModule,
      MatIconModule,
      NgxMaskModule.forRoot(),
  ],
  providers: [{
      provide: LOCALE_ID,
      useValue: 'es-Ar' },
      VersionCheckService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
