import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DataProviderService} from '@app/data-provider.service';
import {ItemTrabajo} from "@app/modulo-trabajo/item-trabajo/item-trabajo";
import {NotaDeCredito} from "@app/modulo-clientes/pago/nota-de-credito/nota-de-credito";
import {Pago} from "@app/modulo-clientes/pago/pago";
import {Trabajo} from "@app/modulo-trabajo/trabajo/trabajo";
import {Usuario} from "../../../models/usuario";

@Injectable()
export class ItemTrabajoService {
    public serviceUrl: string;

    constructor(
        private dataProviderService: DataProviderService,
        private http: HttpClient
        ) {
        this.serviceUrl = this.dataProviderService.getServiceUrl() + 'trabajo/itemTrabajo';
    }

    private static createDTO(elem: ItemTrabajo) {
        let idEstadoOrden = null;
        if (elem.EstadoOrden) {
            idEstadoOrden = elem.EstadoOrden.id;
        }
        let idColor = null;
        if (elem.Color) {
            idColor = elem.Color.id;
        }
        let idResponsable = null;
        if (elem.Responsable && localStorage.getItem('license') == 'full') {
            idResponsable = elem.Responsable.nombreUsuario;
        }
        return {
            id: elem.id,
            idTrabajo: elem.Trabajo.id,
            idColor: idColor,
            idEstadoOrden: idEstadoOrden,
            idOrdenTrabajo: elem.OrdenTrabajo.id,
            idResponsable: idResponsable,
            cantidad: elem.cantidad,
            precio: elem.precioUnitario,
            fecha_ingreso: elem.fecha_ingreso,
            fecha_entrega: elem.fecha_entrega,
            observaciones: elem.observaciones,
        };
    }

    public getElems(filters: any = null): Promise<ItemTrabajo[]> {
        let f = JSON.stringify(filters);
        filters = '';
        if (f) {
            filters = '?filters=' + f;
        }
        return this.http.get(this.serviceUrl + '/get' + filters, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo[]) => {
                return res ? res : [];
            }).catch(this.handleError);
    }

    public getElem(id: number): Promise<ItemTrabajo> {
        return this.http.get(this.serviceUrl + '/get/' + id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo) => {
                return res;
            }).catch(this.handleError);
    }

    public buscarElem(term: string): Promise<ItemTrabajo> {
        return this.http.get(this.serviceUrl + '/' + term + '/buscar', this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo) => {
                return res;
            }).catch(this.handleError);
    }

    public entregarTrabajo(id: number, TrabajoRealizado: Trabajo): Promise<ItemTrabajo> {
        let idTrabajoRealizado = TrabajoRealizado ? TrabajoRealizado.id : null;
        let DTO = {
            idProximoUsuario: null,
            idTrabajoRealizado: idTrabajoRealizado
        }
        return this.http.put(this.serviceUrl + '/'+ id + '/entregar', DTO, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo) => {
                return res;
            }).catch(this.handleError);
    }

    public getElemsByOrdenTrabajo(idOrdenTrabajo: number): Promise<ItemTrabajo[]> {
        return this.http.get(this.serviceUrl + '/getByOrdenTrabajo/' + idOrdenTrabajo, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo[]) => {
                return res;
            }).catch(this.handleError);
    }

    public postElem(elem: ItemTrabajo): Promise<ItemTrabajo> {
        return this.http.post(this.serviceUrl, ItemTrabajoService.createDTO(elem), this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo) => {
                return res;
            }).catch(this.handleError);
    }

    public editElem(elem: ItemTrabajo): Promise<ItemTrabajo> {
        return this.http.patch(this.serviceUrl + '/' + elem.id, ItemTrabajoService.createDTO(elem), this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo) => {
                return res;
            }).catch(this.handleError);
    }

    public cambiarUsuarioResponsable(elem: ItemTrabajo, TrabajoRealizado: Trabajo, ProximoTecnico: Usuario): Promise<ItemTrabajo> {
        let DTO = {
            idProximoUsuario: ProximoTecnico.id,
            idTrabajoRealizado: TrabajoRealizado.id
        }
        return this.http.patch(this.serviceUrl + '/' + elem.id + '/resp/' + ProximoTecnico.nombreUsuario, DTO, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo) => {
                return res;
            }).catch(this.handleError);
    }

    public deleteElem(elem: ItemTrabajo): Promise<any> {
        return this.http.delete(this.serviceUrl + '/' + elem.id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: any) => {
                return res;
            }).catch(this.handleError);
    }

    public generarFichaInternaDeTrabajo(id: number) {
        return this.http.put(this.serviceUrl + '/' + id + '/generarFicha', null, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: any) => {
                return this.dataProviderService.getRootUrl() + 'PDF/' + res.filename;
            }).catch(this.handleError);
    }

    public getTop5TrabajosVencidos(): Promise<ItemTrabajo[]> {
        return this.http.get(this.serviceUrl + '/top5Vencidos', this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo[]) => {
                return res;
            }).catch(this.handleError);
    }

    /** Top de 5 trabajos en estado 'finalizado' que aún no han sido despachados o entregados */
    public getTop5TrabajosEnRecepcion(): Promise<ItemTrabajo[]> {
        return this.http.get(this.serviceUrl + '/top5Recepcion', this.dataProviderService.authHeader())
            .toPromise()
            .then((res: ItemTrabajo[]) => {
                return res;
            }).catch(this.handleError);
    }

    /*
    *    NOTA DE CREDITO
    */
    private createDTONotaDeCredito(elem: NotaDeCredito) {
        return {
            id: elem.id,
            fecha_entrega: elem.fecha, // fecha en la que se quiere registrar la nota de crédito
            monto: elem.monto,
            observaciones: elem.observaciones,
            idCliente: elem.OrdenTrabajoAsociado.Cliente.id,
            esNotaDeCredito: true,
            idOrdenTrabajo: elem.OrdenTrabajoAsociado.id,
            idItemTrabajo: elem.ItemTrabajo.id
        };
    }

    public postNotaDeCredito(elem: NotaDeCredito): Promise<Pago> {
        return this.http.post(this.serviceUrl, this.createDTONotaDeCredito(elem), this.dataProviderService.authHeader())
            .toPromise()
            .then((res: Pago) => {
                return res;
            }).catch(this.handleError);
    }

    private handleError(error: HttpErrorResponse): Promise <any> {
        return Promise.reject(error.error);
    }
}

