import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataProviderService} from "@app/data-provider.service";

@Injectable()
export class LoginService {
    private serviceUrl: string;
    constructor(
        public http: HttpClient,
        private dataProviderService: DataProviderService,
    ) { this.serviceUrl = this.dataProviderService.getServiceUrl() + 'login'; }

    public iniciarSesion(email: string, passwd: string): Promise<any> {
        const header = new HttpHeaders().set('Content-Type', 'application/json');
        const elementoDTO = {
            email: email,
            passwd: passwd,
        };
        return this.http.put(this.serviceUrl, JSON.stringify(elementoDTO), {headers: header})
            .toPromise()
            .then((res) => {
                localStorage.setItem('token', String(res['token']));
                return res;
            });
    }

    public getUserInfo(userId) {
        if (!userId) {
            return;
        }
        return this.http.get(this.serviceUrl + '/' + userId, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public closeSession() {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        return this.http.delete(this.serviceUrl, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        // console.error('An error occurred', error.error); // for demo purposes only
        return Promise.reject(error);
    }
}


