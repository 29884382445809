import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TipoUsuario, Usuario} from '../../../models/usuario';
import {DataProviderService} from "@app/data-provider.service";
import {Globals} from "@app/modulo-global/globals";

@Injectable()
export class UsuarioService {
    private serviceUrl: string;
    constructor(
        private http: HttpClient,
        private dataProviderService: DataProviderService,
        private global: Globals,
    ) { this.serviceUrl = this.dataProviderService.getServiceUrl(); }

    // Solo admitida para administrad
    public getUsuarios(): Promise<Usuario[]> {
        return this.http.get(this.serviceUrl + 'usuarios', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public getQuickUserList(includeMe: boolean = false): Promise<any> {
        let url = '';
        if (includeMe) {
            url = '/all';
        }
        return this.http.get(this.serviceUrl + 'usuarios/getQuickUserList' + url, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public getUserDataFromServer(id: number = null): Promise<Usuario> {
        let request = this.serviceUrl + 'usuario';
        if (id != null) {
            request += '/' + id;
        }
        return this.http.get(request, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                this.global.setUsuario(res);
                return res;
            })
            .catch(this.handleError);
    }

    public getTecnicos(): Promise<any> {
        return this.http.get(this.serviceUrl + 'usuarios/getTecnicos', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public getUserSessionFromServer(): Promise<any> {
        return this.http.get(this.serviceUrl + 'session', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    // Retorna todos los tipos de usuario
    public getTipoUsuarios(): Promise<TipoUsuario[]> {
        return this.http.get(this.serviceUrl + 'tipoUsuario', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    // Se envía un usuario sobreescribiendo todos sus atributos
    public editarUsuario(usuario: Usuario): Promise<Usuario> {
        return this.http.patch(this.serviceUrl + 'usuario/' + usuario.id, JSON.stringify(this.createDTO(usuario)), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public agregarUsuario(usuario: Usuario): Promise<Usuario> {
        return this.http.post(this.serviceUrl + 'usuario', JSON.stringify(this.createDTO(usuario)), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    private createDTO(usuario: Usuario) {
        let idAreaTrabajo = null;
        if (usuario.AreaTrabajo && usuario.AreaTrabajo.id) {
            idAreaTrabajo = usuario.AreaTrabajo.id;
        }
        return {
            nombre: usuario.nombre,
            nombreUsuario: usuario.nombreUsuario,
            telefono1: usuario.telefono1,
            telefono2: usuario.telefono2,
            email: usuario.email,
            idTipoUsuario: usuario.TipoUsuario.id,
            activo: usuario.activo,
            idAreaTrabajo,
        }
    }

    public borrarUsuario(usuario: Usuario): Promise<any> {
        return this.http.delete(this.serviceUrl + 'usuario/' + usuario.id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public bloquearUsuario(usuario: Usuario): Promise<boolean> {
        return this.http.put(this.serviceUrl + 'usuario/' + usuario.id, null, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public resetPassword(usuario: Usuario, pass: string) {
        let url = this.serviceUrl + 'usuario/' + usuario.id + '/resetPasswd';
        const usuarioDTO = {
            password: pass,
        };
        return this.http.put(url, JSON.stringify(usuarioDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    public resetSMBPassword(usuario: Usuario, pass: string) {
        let url = this.serviceUrl + 'usuario/' + usuario.id + '/resetSMBPasswd';
        const usuarioDTO = {
            password: pass,
        };
        return this.http.put(url, JSON.stringify(usuarioDTO), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        // console.error('An error occurred', error.error); // for demo purposes only
        return Promise.reject(error.error);
    }


    /*** MODULO DE ASISTENCIAS ***/
    // Este modulo requiere los datos desde otro backend para Microsoft SQL Server.
    // Por lo que las rutinas aquí serán distintas que en el resto del sistema
    public getAsistencias(username = null, showToday: boolean = false): Promise <any> {
        let param = '';
        if (username) {
            param = '/' + username;
        } else {
            param = '/all';
        }
        return this.http.get(this.serviceUrl + 'usuario/horarios/' + showToday + param, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public updateRegs(): Promise <any> {
        return this.http.get(this.serviceUrl + 'usuario/horarios/update-asistencias', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res['mensaje'];
            })
            .catch(this.handleError)
    }

    public addRegAsistencia(nombre_usuario, fecha, hora, observaciones): Promise <any> {
        const params = {
            username: nombre_usuario,
            fecha: fecha,
            hora: hora,
            observaciones: observaciones,
        };
        return this.http.post(this.serviceUrl + 'usuario/horarios/add-asistencia', JSON.stringify(params), this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res['mensaje'];
            })
            .catch(this.handleError)
    }

    public detalleFecha(fecha, nombre_usuario): Promise<any> {
        let usr = '';
        if (nombre_usuario) {
            usr = '/' + nombre_usuario;
        }
        return this.http.get(this.serviceUrl + 'usuario/horarios/detalle-horarios/' + fecha + usr, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public borrarRegistro(id): Promise<any> {
        return this.http.delete(this.serviceUrl + 'usuario/horarios/borrar-horario/' + id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public editarRegistro(id, checktime, observaciones): Promise<any> {
        const elementoDTO = {
            id: id,
            checktime: checktime,
            observaciones: observaciones,
        };
        return this.http.patch(this.serviceUrl + 'usuario/horarios/editar-horario/' + id, elementoDTO, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public chequearAsistenciaHoy(): Promise <any> {
        return this.http.get(this.serviceUrl + 'usuario/horarios/asistencia-hoy', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public chequearUltimaActualizacionDeRegistros(): Promise <any> {
        return this.http.get(this.serviceUrl + 'usuario/horarios/ultima-actualizacion', this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }

    public obtenerSumarioHorasTrabajadas(nombre_usuario, ultimoMes): Promise <any> {
        let str = '';
        str += nombre_usuario ? '/u/' + nombre_usuario : '';
        str += ultimoMes ? '/p/true' : '';
        return this.http.get(this.serviceUrl + 'usuario/horarios/sumario-horas' + str, this.dataProviderService.authHeader())
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError)
    }
}


