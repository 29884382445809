import {Component, OnInit} from '@angular/core';
import {UserService} from "@app/core/services";


@Component({
  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  public rol: any;
  constructor (
      private us: UserService
  ) {
  }

  ngOnInit() {
    this.rol = this.us.getRole()
        .then((res) => {
          this.rol = res;
        });
  }

}
