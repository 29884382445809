import {Component, OnInit, ElementRef, Renderer, OnDestroy} from '@angular/core';
import {ActivitiesService} from "./activities.service";
import {Globals} from "@app/modulo-global/globals";
import {forkJoin} from "rxjs";

declare var $: any;

@Component({
    selector: 'sa-activities',
    templateUrl: './activities.component.html',
    providers: [ActivitiesService],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
    count: number;
    lastUpdate: any;
    active: boolean;
    activities: any;
    currentActivity: any;
    loading: boolean;
    public fechaHoy: any;
    public tareasVencidas: number;

    public notif_task: any = {
        id: null,
        timestamp: null
    };

    public array_notification;

    constructor(
        private el: ElementRef,
        private renderer: Renderer,
        private activitiesService: ActivitiesService,
        private global: Globals,
    ) {
        this.active = false;
        this.loading = false;
        this.activities = [];
        this.count = 0;
        this.lastUpdate = new Date();
        this.fechaHoy = new Date();
        this.tareasVencidas = 0;
    }

    ngOnInit() {
        this.update();
    }

    setActivity(activity) {
        this.currentActivity = activity;
    }

    private documentSub: any;

    onToggle() {
        let dropdown = $('.ajax-dropdown', this.el.nativeElement);
        this.active = !this.active;
        if (this.active) {
            dropdown.fadeIn();

            this.documentSub = this.renderer.listenGlobal('document', 'mouseup', (event) => {
                if (!this.el.nativeElement.contains(event.target)) {
                    dropdown.fadeOut();
                    this.active = false;
                    this.documentUnsub()
                }
            });


        } else {
            dropdown.fadeOut();

            this.documentUnsub()
        }
    }


    update() {
        this.loading = true;
        this.count = 0;
        this.tareasVencidas = 0;
        this.array_notification = [];

        this.activitiesService.getActivities().subscribe(data => {
            this.activities = data;
            forkJoin(
                //this.tareaService.misTareas(),
                //this.tareaService.obtenerTareasCompartidas(),
            ).subscribe(([misTareas, tareasAsignadas]: [any, any]) => {
                let tasks = [];
                misTareas.forEach((value: any, key: string) => {
                    value.type = 'tarea';
                });
                for (let i = 0; i < misTareas.length; i++) {
                    tasks.push(misTareas[i]);
                    if (misTareas[i].fecha_programada) {
                        if (this.fechaHoy >= new Date(misTareas[i].fecha_programada)) {
                            this.tareasVencidas++;
                        }
                        if (this.global.calcularHorasRestantes(new Date(), misTareas[i].fecha_programada)) {
                            this.actualizarRegistroNotificacionTareas(misTareas[i].id_tarea);
                        }
                    }
                }
                tareasAsignadas.forEach((value: any, key: string) => {
                    value.type = 'tarea';
                });
                for (let i = 0; i < tareasAsignadas.length; i++) {
                    tasks.push(tareasAsignadas[i]);
                    if (tareasAsignadas[i].fecha_programada) {
                        if (this.fechaHoy >= new Date(tareasAsignadas[i].fecha_programada)) {
                            this.tareasVencidas++;
                        }
                        if (this.global.calcularHorasRestantes(new Date(), tareasAsignadas[i].fecha_programada)) {
                            this.actualizarRegistroNotificacionTareas(tareasAsignadas[i].id_tarea);
                        }
                    }
                }
                for (let i = 1; i < this.activities.length; i++) {
                    if (this.activities[i].name == 'tareas') {
                        this.activities[i].data = tasks;
                        break;
                    }
                }
                this.count += this.tareasVencidas;
                localStorage.setItem('notif_task', JSON.stringify(this.array_notification));
                localStorage.removeItem('temporizador_task_id');
            });

            // this.count = data.reduce((sum, it) => sum + it.data.length, 0);
            this.currentActivity = data[2];
        });

        this.lastUpdate = new Date();
        this.loading = false;
    }


    ngOnDestroy() {
        this.documentUnsub()
    }

    documentUnsub() {
        this.documentSub && this.documentSub();
        this.documentSub = null
    }

    public actualizarRegistroNotificacionTareas(id) {
        this.notif_task.id = id;
        this.notif_task.timestamp = null;

        let json = JSON.parse(localStorage.getItem('notif_task'));
        if (!json) {
            this.array_notification.push(this.notif_task);
            return;
        }
        for (let i = 0; i < json.length; i++) {
            if (json[i].id == id) {
                this.array_notification.push(json[i]);
                return;
            }
        }
        this.array_notification.push(this.notif_task);
    }
}
