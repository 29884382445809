import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DataProviderService} from '@app/data-provider.service';
import {OrdenTrabajo} from "@app/modulo-trabajo/orden-trabajo/orden-trabajo";
import {Observable} from "rxjs/Observable";

@Injectable()
export class OrdenTrabajoService {
    public serviceUrl: string;

    constructor(
        private dataProviderService: DataProviderService,
        private http: HttpClient
        ) {
        this.serviceUrl = this.dataProviderService.getServiceUrl() + 'trabajo/ordenTrabajo';
    }

    private static createDTO(elem: OrdenTrabajo) {
        return {
            id: elem.id,
            numero_orden: elem.numero_orden,
            idCliente: elem.Cliente.id,
            nombrePaciente: elem.nombrePaciente,
            fecha_creacion: elem.fecha_creacion,
            fecha_finalizacion: elem.fecha_finalizacion,
            Usuario: elem.Usuario,
            observaciones: elem.observaciones,
        };
    }

    public getElems(filters: any = null): Promise<OrdenTrabajo[]> {
        let f = JSON.stringify(filters);
        filters = '';
        if (f) {
            filters = '?filters=' + f;
        }
        return this.http.get(this.serviceUrl + '/get' + filters, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: OrdenTrabajo[]) => {
                return res ? res : [];
            }).catch(OrdenTrabajoService.handleError);
    }

    public getElem(id: number): Promise<OrdenTrabajo> {
        return this.http.get(this.serviceUrl + '/get/' + id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: OrdenTrabajo) => {
                return res;
            }).catch(OrdenTrabajoService.handleError);
    }

    public buscarOrden(term: string, strict: boolean = false): Promise<any> {
        return this.http.get(this.serviceUrl + '/' + term + '/buscar?strict=' + strict, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: any) => {
                return res;
            }).catch(OrdenTrabajoService.handleError);
    }

    public buscarOrdenObservable(term: string, strict: boolean = false): Observable<{historico: boolean, OrdenTrabajo : OrdenTrabajo[], unique: boolean}> {
        return this.http.get(this.serviceUrl + '/' + term + '/buscar?strict=' + strict, this.dataProviderService.authHeader())
            .pipe((res: any) => {
                return res;
            });
    }

    public postElem(elem: OrdenTrabajo): Promise<OrdenTrabajo> {
        return this.http.post(this.serviceUrl, OrdenTrabajoService.createDTO(elem), this.dataProviderService.authHeader())
            .toPromise()
            .then((res: OrdenTrabajo) => {
                return res;
            }).catch(OrdenTrabajoService.handleError);
    }

    public editElem(elem: OrdenTrabajo): Promise<OrdenTrabajo> {
        return this.http.patch(this.serviceUrl + '/' + elem.id, OrdenTrabajoService.createDTO(elem), this.dataProviderService.authHeader())
            .toPromise()
            .then((res: OrdenTrabajo) => {
                return res;
            }).catch(OrdenTrabajoService.handleError);
    }

    public deleteElem(elem: OrdenTrabajo): Promise<any> {
        return this.http.delete(this.serviceUrl + '/' + elem.id, this.dataProviderService.authHeader())
            .toPromise()
            .then((res: any) => {
                return res;
            }).catch(OrdenTrabajoService.handleError);
    }

    private static handleError(error: HttpErrorResponse): Promise <any> {
        return Promise.reject(error.error);
    }
}

