import {Component, OnInit, Input} from '@angular/core';
import {Globals} from "@app/modulo-global/globals";
import {NotificationService} from "@app/core/services";
import * as moment from "smartadmin-plugins/bower_components/moment/moment";
import {Router} from "@angular/router";

@Component({
    selector: '[activitiesTask]',
    templateUrl: './activities-task.component.html',
})
export class ActivitiesTaskComponent implements OnInit {

    @Input() item: any;
    @Input() lastUpdate: any;

    constructor(
        private global: Globals,
        private notificationService: NotificationService,
        private router: Router,
    ) {
    }

    public notif_task: any = {
        id: null,
        timestamp: null
    };

    ngOnInit() {
        let fechaHoy = new Date();
        if (this.item.fecha_programada) {
            let f = new Date(this.item.fecha_programada);
            this.item.vencido = fechaHoy >= f;
            this.item.tsk = this.global.calculateDiffDates(new Date(), this.item.fecha_programada);
            if (this.item.tsk == "A las 00:00") {
                this.item.tsk = null;
                this.item.horas = null;
            } else {
                this.item.horas = this.global.calcularHorasRestantes(new Date(), this.item.fecha_programada);
            }
            this.emitirNotificacion();
            if (!this.item.vencido && this.item.horas && !this.getItem('temporizador_task_id', this.item.id_tarea)) {
                this.temporizadorNotificacionCita();
            }
        }
    }

    public emitirNotificacion() {
        // var ultima_emision = new Date(localStorage.getItem('notif_task_id' + this.item.id_tarea));
        var ultima_emision = this.getItem('notif_task', this.item.id_tarea);
        if (!ultima_emision) {
            this.setItem('notif_task', this.item.id_tarea, (new Date()).toISOString());
        } else {
            // Esperar X minutos para volver a emitir la notificación
            var newDateObj = moment(ultima_emision).add(10, 'm').toDate();
            if (newDateObj > new Date()) {
                // Si no se cumplieron los minutos necesarios, no emito el recordatorio
                return;
            }
        }

        // localStorage.setItem('notif_task_id' + this.item.id_tarea, (new Date()).toISOString());
        this.setItem('notif_task', this.item.id_tarea, (new Date()).toISOString());

        let color = '#4170c4';
        let icono = 'fa fa-calendar-o shake animated';
        if (this.item.vencido) {
            color = '#cc9132';
            icono = 'fa fa-warning shake animated';
        }
        if (!this.item.descripcion) {
            this.item.descripcion = 'Sin detalles sobre esta tarea';
        }

        if (this.item.horas) {
            let h = this.global.calcularHorasRestantes(new Date(), this.item.fecha_programada, true).slice(0,2);
            if (h != '00') {
                // no emitir notificación de tareas de más de una hora adelante
                return;
            }

            this.notificationService.bigBox({
                title: this.item.titulo_tarea,
                content: this.item.descripcion,
                color: color,
                icon: icono,
                number: this.item.horas,
            });
        }
    }

    public temporizadorNotificacionCita() {
        // let tid = localStorage.getItem('temporizador_task_id' + this.item.id_tarea);
        let tid = this.getItem('temporizador_task_id', this.item.id_tarea);
        if (tid) {
            return;
        }
        let fp = new Date(this.item.fecha_programada);
        this.setItem('temporizador_task_id', this.item.id_tarea, fp.toISOString());
        var interval = setInterval(() => {
            let t = this.global.calcularHorasRestantes(new Date(), this.item.fecha_programada, true)
            if (t == '00:00' || t == '05:00') {
                if (!this.item.descripcion) {
                    this.item.descripcion = 'Sin detalles sobre esta tarea';
                }
                let momento = 'Ahora';
                if (t == '05:00') {
                    momento = 'En 5 minutos';
                }
                this.notificationService.bigBox({
                    title: this.item.titulo_tarea,
                    content: this.item.descripcion,
                    color: '#198dc4',
                    icon: 'fa fa-clock-o fa-spin',
                    number: momento,
                });
                // localStorage.removeItem('temporizador_task_id' + this.item.id_tarea);
                this.removeItem('temporizador_task_id', this.item.id_tarea);
                clearInterval(interval);
            }
        }, 55000);
    }

    public getItem(key, id) {
        let json = JSON.parse(localStorage.getItem(key));
        if (!json) {
            return null;
        }
        for (let i = 0; i < json.length; i++) {
            if (json[i].id == id) {
                return json[i].timestamp;
            }
        }
        return null;
    }

    public setItem(key, id, val) {
        let json = JSON.parse(localStorage.getItem(key));
        if (!json) {
            this.notif_task.id = this.item.id_tarea;
            this.notif_task.timestamp = val;
            json = [];
            json.push(this.notif_task);
            localStorage.setItem(key, JSON.stringify(json));
            return;
        }
        for (let i = 0; i < json.length; i++) {
            if (json[i].id == id) {
                json[i].timestamp = val;
                localStorage.setItem(key, JSON.stringify(json));
                return;
            }
        }
        this.notif_task.id = this.item.id_tarea;
        this.notif_task.timestamp = val;
        json.push(this.notif_task);
        localStorage.setItem(key, JSON.stringify(json));
    }

    public removeItem(key, id) {
        let json = JSON.parse(localStorage.getItem(key));
        if (!json) {
            return null;
        }
        let res = json.filter(x => x.id != id);
        localStorage.setItem(key, JSON.stringify(res));
    }

    public openTask(item) {
        this.router.navigate(["agenda/tareas/1"], {
            queryParams: {
                'idEstadoTarea': 1,
                'id': item.id_tarea,
            }
        });
    }
}
