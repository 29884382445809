import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import {TrustedHtmlPipe} from "./trusted-html.pipe";
import {RoundPipe} from "@app/shared/pipes/round.pipe";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FieldFilterPipe,
    MomentPipe,
    TrustedHtmlPipe,
      RoundPipe
  ],
  exports: [
    FieldFilterPipe,
    MomentPipe,
    TrustedHtmlPipe,
    RoundPipe
  ]
})
export class PipesModule { }
