import {Component} from '@angular/core';
import {environment} from "@env/environment";
import {VersionCheckService} from "@app/shared/utils/version-checker.service";

@Component({
    selector: 'app-root',
    template: '<router-outlet (activate)="onActivate($event)"></router-outlet>',
})
export class AppComponent {
    title = 'sa';

    constructor(
        private versionCheckService: VersionCheckService
    ) {
    }

    ngOnInit() {
        this.versionCheckService.initVersionCheck(environment.versionCheckFile);
    }

    onActivate(event) {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    }
}
