import {Component, OnInit} from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { LayoutService } from '@app/core/services/layout.service';
import {Router} from "@angular/router";
import {Globals} from "@app/modulo-global/globals";

@Component({
  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {

  user: any;

  constructor(
      private userService: UserService,
      private router: Router,
      private layoutService: LayoutService,
      public global: Globals
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('userId') && Number(localStorage.getItem('userId')) > 0) {
      if (!this.global.getUsuario()) {
        this.user = this.userService.getLoginInfo();
        this.global.setUsuario(this.user);
      }
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}
