import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {config} from "@app/core/smartadmin.config";
import {ItemTrabajoService} from "@app/modulo-trabajo/item-trabajo/item-trabajo.service";
import {OrdenTrabajoService} from "@app/modulo-trabajo/orden-trabajo/orden-trabajo.service";
import {NotificationService} from "@app/core/services";
import {Location} from "@angular/common";

declare var $: any;

@Component({
    selector: 'sa-header',
    templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {

    public store = {
        smartSkin: localStorage.getItem('sm-skin') || config.smartSkin,
        skin: config.skins.find((_skin) => {
            return _skin.name == (localStorage.getItem('sm-skin') || config.smartSkin)
        })
    };

    public buscarTerm: string;

    constructor(
        private router: Router,
        private itemTrabajoService: ItemTrabajoService,
        private ordenTrabajoService: OrdenTrabajoService,
        private notificationService: NotificationService,
        private _location: Location
    ) {
    }

    ngOnInit() {
        let skin = config.smartSkin;
        if (!localStorage.getItem('sm-skin')) {
            skin = localStorage.getItem('sm-skin');
        }
    }


    searchMobileActive = false;

    toggleSearchMobile() {
        this.searchMobileActive = !this.searchMobileActive;

        $('body').toggleClass('search-mobile', this.searchMobileActive);
    }

    /**
     * Derivar la búsqueda al componente correspondiente
     */
    onSubmit() {
        this.router.navigate(['/trabajos/buscar'], {
            queryParams: {'term': this.buscarTerm}
        });
        this.buscarTerm = '';
    }
}
