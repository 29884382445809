import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "@env/environment";
import {DataProviderService} from "@app/data-provider.service";
import {NotificationService} from "@app/core/services";

@Injectable()
export class VersionCheckService {
    private currentVersion = environment.version;

    constructor(
        private http: HttpClient,
        private dataProviderService: DataProviderService,
        private notificationService: NotificationService,
    ) {
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 10 minutes
     */
    public initVersionCheck(url, frequency = 1000 * 60 * 5) {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
// timestamp these requests to invalidate caches
        this.http.get(this.dataProviderService.getServiceUrl() + 'environment', this.dataProviderService.authHeader())
            .subscribe(
                (response: any) => {
                    const version = response.version;
                    this.updateLicenseType(response.license);
                    const versionChanged = this.hasVersionChanged(this.currentVersion, version);
                    // If new version, do something
                    if (versionChanged) {
                        //window.location.reload();
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                        this.notificationService.smallBox({
                            title: "<span title='Esta versión: "+ this.currentVersion + "'><b>Actualización disponible</b></span>",
                            content: "Hay una nueva versión disponible del sistema.<br>" + version + " <p class='text-align-right'><a href-void class='btn btn-danger btn-sm'>Actualizar</a></p>",
                            color: "#296191",
                            icon: "fa fa-bell swing animated"
                        }, (ButtonPress, Value) => {
                            window.location.reload();
                        });
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentVersion = version;
                },
                (err) => {
                    console.error(err, 'Error al verificar si existe nueva versión del sistema');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentVersion
     * @param newVersion
     * @returns {boolean}
     */
    private hasVersionChanged(currentVersion, newVersion) {
        if (!currentVersion) {
            return false;
        }
        return currentVersion !== newVersion;
    }

    /**
     * Update browser local storage
     * Update local storage with client's license type
     * @param license
     * @returns null
     */
    private updateLicenseType(license) {
        localStorage.setItem('license', license);
    }
}
