import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class DataProviderService {
    // Produccion
    private url = '../';


    // Develop
    // private url = 'http://localhost/';
    // private url = 'http://ondent.com.ar/';

    constructor() {
    }

    public getServiceUrl() {
        return this.url + 'backend/public/index.php/api/';
    }

    public getServerUrl() {
        return this.url;
    }

    public getRootUrl() {
        return this.url + 'backend/public/';
    }

    public authHeader() {
        const Headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        });
        return {headers: Headers};
    }

    public authPdfHeader() {
        const Headers = new HttpHeaders({
            'Content-Type': 'application/pdf',
            'Accept': 'application/pdf',
            'x-auth-token': localStorage.getItem('token'),
            'responseType' : 'blob'
        });
        return {headers: Headers};
    }

}
