import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";

import {MainLayoutComponent} from "./shared/layout/app-layouts/main-layout.component";
import {AuthLayoutComponent} from "./shared/layout/app-layouts/auth-layout.component";
import {TecnicoLayoutComponent} from "@app/shared/layout/app-layouts/tecnico-layout.component";

const routes: Routes = [
    {
        path: '',
        component: AuthLayoutComponent,
        data: {pageTitle: 'Login'},
        children: [
            {
                path: '', redirectTo: 'auth/login', pathMatch: 'full',
            },
            {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule', data: {pageTitle: 'Login'}},
        ]
    },
    {
        path: '',
        component: MainLayoutComponent,
        data: {pageTitle: 'Principal'},
        children: [
            {
                path: '', redirectTo: 'principal', pathMatch: 'full',
            },
            {path: 'principal', loadChildren: 'app/home/home.module#HomeModule', data: {pageTitle: 'Principal'}},
            {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule', data: {pageTitle: 'Login'}},
            {
                path: 'usuario',
                loadChildren: 'app/modulo-usuario/usuario/usuario.module#UsuarioModule',
                data: {pageTitle: 'Usuario'}
            },
            {
                path: 'pagos',
                loadChildren: 'app/modulo-clientes/pago/pago.module#PagoModule',
                data: {pageTitle: 'Pagos de clientes'}
            },
        ]
    },
    {
        path: 'clientes',
        component: MainLayoutComponent,
        data: {pageTitle: 'Clientes'},
        children: [
            {
                path: '',
                loadChildren: 'app/modulo-clientes/clientes/cliente.module#ClienteModule',
                data: {pageTitle: 'Clientes'}
            },
            {
                path: 'cuentas-corrientes',
                loadChildren: 'app/modulo-clientes/cuenta-corriente/cuenta-corriente.module#CuentaCorrienteModule',
                data: {pageTitle: 'Clientes'}
            },
        ]
    },
    {
        path: 'audit',
        component: MainLayoutComponent,
        data: {pageTitle: 'Auditoría'},
        children: [
            {
                path: '',
                loadChildren: 'app/modulo-clientes/clientes/cliente.module#ClienteModule',
                data: {pageTitle: 'Clientes'}
            },
            {
                path: 'por-area',
                loadChildren: 'app/modulo-audit/auditar-por-area/auditar-por-area.module#AuditarPorAreaModule',
                data: {pageTitle: 'Por áreas'}
            },
            {
                path: 'trabajos',
                loadChildren: 'app/modulo-audit/auditar-trabajos/auditar-trabajos.module#AuditarTrabajosModule',
                data: {pageTitle: 'Por trabajos'}
            },
        ]
    },
    {
        path: 'parametros',
        component: MainLayoutComponent,
        data: {pageTitle: 'Parámetros'},
        children: [
            {
                path: 'paleta-color',
                loadChildren: 'app/modulo-parametros/color/color.module#ColorModule',
                data: {pageTitle: 'Paleta de color'}
            },
            {
                path: 'banco',
                loadChildren: 'app/modulo-parametros/banco/banco.module#BancoModule',
                data: {pageTitle: 'Bancos'}
            },
            {
                path: 'tipo-pago',
                loadChildren: 'app/modulo-parametros/tipo-pago/tipo-pago.module#TipoPagoModule',
                data: {pageTitle: 'Tipos de pago aceptados'}
            },
            {
                path: 'estado-orden',
                loadChildren: 'app/modulo-parametros/estado-orden/estado-orden.module#EstadoOrdenModule',
                data: {pageTitle: 'Estados de ordenes'}
            },
            {
                path: 'clasificacion-trabajo',
                loadChildren: 'app/modulo-trabajo/clasificacion-trabajo/clasificacion-trabajo.module#ClasificacionTrabajoModule',
                data: {pageTitle: 'Clasificación de tipos de trabajos'}
            },
            {
                path: 'parametros',
                loadChildren: 'app/modulo-parametros/parametros/parametros.module#ParametrosModule',
                data: {pageTitle: 'Parámetros del sistema'}
            },
            {
                path: 'logs',
                loadChildren: 'app/modulo-parametros/logs/logs.module#LogsModule',
                data: {pageTitle: 'Registro de eventos'}
            }
            //{path: 'parametros', loadChildren: () => import('./modulo-parametros/color/color.module').then(m => m.PagoModule)},
        ]
    },
    {
        path: 'trabajos',
        component: MainLayoutComponent,
        data: {pageTitle: 'Trabajos'},
        children: [
            {
                path: 'nueva-orden',
                loadChildren: 'app/modulo-trabajo/orden-trabajo/orden-trabajo.module#OrdenTrabajoModule',
                data: {pageTitle: 'Orden de trabajo'}
            },
            {
                path: 'listado-trabajos',
                loadChildren: 'app/modulo-trabajo/trabajo/trabajo.module#TrabajoModule',
                data: {pageTitle: 'Listado de trabajos'}
            },
            {
                path: 'en-proceso',
                loadChildren: 'app/modulo-trabajo/en-proceso/en-proceso.module#EnProcesoModule',
                data: {pageTitle: 'Listado de trabajos en proceso'}
            },
            {
                path: 'historico',
                loadChildren: 'app/modulo-trabajo/historico/historico.module#HistoricoModule',
                data: {pageTitle: 'Registros históricos'}
            },
            {
                path: 'buscar',
                loadChildren: 'app/modulo-trabajo/buscador/buscador.module#BuscadorModule',
                data: {pageTitle: 'Buscador'}
            }
    ]},
    {
        path: 'tecnico',
        component: TecnicoLayoutComponent,
        data: {pageTitle: 'Trabajos'},
        children: [
            {
                path: 'agenda',
                loadChildren: 'app/modulo-trabajo/agenda-tecnico/agenda-tecnico.module#AgendaTecnicoModule',
                data: {pageTitle: 'Agenda de trabajos'}
            }
    ]},

    //{path: '**', redirectTo: 'principal'}
    //{ path: "**", redirectTo: "miscellaneous/error404" }
    {
         path: '',
         component: MainLayoutComponent,
         data: {pageTitle: 'Página no encontrada'},
         children: [
             {
                 path: '**', loadChildren: 'app/modulo-usuario/error404/error404.module#Error404Module'
             }
         ]
     },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: "reload"})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
