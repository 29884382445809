import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login(): Observable<boolean> {
    return Observable.prototype;
  }

  logout(): void {
    this.isLoggedIn = false;
  }
}
