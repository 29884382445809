import { Component, OnInit } from '@angular/core';
import {environment} from "@env/environment";

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public version: string;
  constructor() {}

  ngOnInit() {
    this.version = environment.version;
  }

}
