import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'trustedHtml'
})
export class TrustedHtmlPipe implements PipeTransform  {

    constructor(private _sanitizer: DomSanitizer){}

    transform(html: string) : SafeHtml {
        if (html) {
            return this._sanitizer.bypassSecurityTrustHtml(html);
        }
        return '';
    }
}
