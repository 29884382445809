import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@app/core/services/notification.service";

import {UserService} from "@app/core/services/user.service";
import {LoginService} from "@app/auth/login/login.service";

@Component({
  selector: "sa-logout",
  template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a title="Cerrar sesión"><i class="fa fa-power-off"></i></a> </span>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  public user;

  constructor(
      private loginService: LoginService,
      private userService: UserService,
      private router: Router,
      private notificationService: NotificationService
  ) {
  }

  showPopup() {
    this.notificationService.smartMessageBox(
      {
        title:
          "<i class='fa fa-sign-out txt-color-orangeDark'></i> Cerrar sesión <span class='txt-color-orangeDark'><strong>" + this.userService.userInfo.username +"</strong></span> ?",
        content:
          "¿Desea cerrar sesión?",
        buttons: "[No][Cerrar]"
      },
      ButtonPressed => {
        if (ButtonPressed == "Cerrar") {
          this.logout();
        }
      }
    );
  }

  logout() {
    this.loginService.closeSession();
    this.router.navigate(["/auth/login"]);
  }

  ngOnInit() {}
}
